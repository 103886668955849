@import url("/src/media-query/zoom-level-100.css");

:root {
  --layout-header-footer-background-color: #f0f2f5;
  --layout-header-height: 10vh;
  --layout-footer-height: 8vh;
  --page-height: 100vh;
  --page-width: 100vw;
  --layout-content-height: 80vh;
  --layout-content-width: 100%;
  --layout-sider-width: 250px;
  --sidebar-background-color: #313548;
  --layout-content-background-color: #f0f2f5;
  --layout-menu-selected-background-color: rgba(41, 50, 60, 0.95);
  --sider-bottom-menu-color: rgba(255, 255, 255, 0.65);
  --default-box-shadow-color: rgb(60 64 67 / 30%);
  --secondary-box-shadow-color: rgb(60 64 67 / 15%);
  --default-grey: rgb(106, 116, 133);
  --highlight-red: #fa8072;
  --highlight-green: #9acd32;
  --highlight-purple: #9a32cd;
  --highlight-orange: #ffbf00;
  --highlight-blue: #5bc0de;
  --default-green: #0f9668;
  --secondary-green: #0f966814;
  --default-red: #fa4d4f;
  --default-highlight: var(--highlight-red);
  --default-white: white;
  --default-brown: #726767;
  --default-blue: #0b57d0;
  --blue-light: #3a8ff3;
  --blue-violet: blueviolet;
  --default-black: rgb(6, 6, 6);
  --light-background-color: #fafafa;
  --default-background: #242730;
  --secondary-background: #e2e2e2;
  --default-hover-color: #9199a1;
  --default-grey: rgb(106, 116, 133);
  --secondary-grey: rgb(147, 155, 170);
  --secondary-green: #ebf3ef;
  --secondary-white: #f3f4f866;
  --shade: rgb(58, 69, 82, 0.5);
  --dark-shade: rgb(36, 39, 48);
  --light-shade: rgb(106 116 133/35%);
  --light-text: rgba(255, 255, 255, 0.65);
  --light-button-blue: #4485ea;
  --light-blue: #e9ecf9;
  --light-orange: rgba(227, 147, 55, 0.15);
  --light-grey: #f3f4f8;
  --light-green: #9dc4b1;
  --light-red: #ffe6e6;
  --default-cta: #f04f14;
  --primary-background: #27187e;
  --dark-red: #b71212;
  --dark-grey: #676767;
  --border-blue: #4485ea;
  --grey-shade: #ab8f8f;
  --light-grey-shade: rgba(20, 20, 20, 0.04);
  --primary-background-gradient: linear-gradient(285.98deg,
      #211478 -5.26%,
      #38349e 66.37%,
      #4c4ebd 92.48%);
  --dropdown-item-hover: #dbe0ec;
  --title-border: #758bfd;
  --divider-border: #e39337;
  --hyperlink-color: #3a8ff3;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  border-radius: 50px !important;
}

::-webkit-scrollbar-thumb {
  background-color: lightgrey !important;
  border-radius: 50px !important;
}

.login-container {
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-overlay {
  background: #f0f2f5;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.login-main {
  position: absolute;
  top: 0;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-card,
.signup-card,
.forgot-password-card {
  width: 450px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.sider {
  height: 98.5vh;
  background: var(--sidebar-background-color) !important;
  margin: 5px;
  border-radius: 20px;
  position: fixed !important;
  z-index: 999 !important;
}

.sider .ant-menu-dark .ant-menu-item:hover {
  color: var(--default-black) !important;
}

.sider .ant-menu-dark .ant-menu-item-selected,
.sider .ant-menu-dark .ant-menu-submenu-selected,
.sider .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
  color: var(--default-background);
}

.sider .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span,
.ant-menu-dark .ant-menu-item-selected .anticon+span {
  color: var(--default-black) !important;
}

.sider .ant-menu-submenu-selected .ant-menu-submenu-arrow::after,
.sider .ant-menu-submenu-selected .ant-menu-submenu-arrow::before {
  background: var(--default-black) !important;
}

.sider .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: var(--default-black);
}

.sider .ant-menu,
.footer .ant-menu {
  background: var(--sidebar-background-color) !important;
}

.sider .ant-menu-inline {
  width: 95%;
}

.sider .ant-layout-sider-trigger {
  background: transparent !important;
  /* bottom: 50px; */
}

.top-menu .ant-menu-item {
  display: flex !important;
  align-items: center !important;
}

.ant-menu-dark .ant-menu-sub {
  background: var(--sidebar-background-color) !important;
  min-width: 85px !important;
  border-radius: 0px 20px 20px 0px !important;
}

.ant-drawer-mask {
  position: absolute;
  left: 88px !important;
}

.sider .top-menu .ant-menu-item:hover {
  color: var(--default-black) !important;
}

.sider .bottom-menu {
  position: absolute !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 80px;
  width: 100%;
}

.sider .bottom-menu.collapsed {
  bottom: 100px;
}

.sider .bottom-menu button:hover {
  color: var(--default-white) !important;
}

.sider .bottom-menu button {
  color: var(--default-white) !important;
}

.sider .bottom-menu button:hover {
  /* color: var(--default-white) !important; */
  border-radius: 10px;
}

.sider .bottom-menu button {
  color: var(--sider-bottom-menu-color);
}

.sider .ant-menu-dark .ant-menu-item {
  color: var(--default-white);
}

.sider .ant-menu-item-selected:not(.user-control, .user-help),
.sider .ant-menu-item-active,
.sider .ant-menu-submenu-selected .ant-menu-submenu-title {
  background: var(--default-white) !important;
  border-radius: 20px 0 0 20px;
  color: var(--default-background);
  font-weight: 500;
}

.sider .ant-menu-item-selected svg:not(.user-control svg, .user-help svg) {
  margin-left: -2px;
  font-size: 1.2rem;
}

.top-menu .ant-menu-item,
.top-menu .ant-menu-submenu {
  gap: 5px !important;
  border-radius: 20px 0 0 20px;
}

/* .data-stories .slick-slide {
  max-width: 300px !important;
} */

/* .data-stories .ant-carousel .slick-slide {
  float: none;
  margin: 15px 0;
} */

/* .data-stories .ant-carousel .slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
  width: auto !important;
} */

.main-menu-avatar svg {
  margin: 0px !important;
}

.header {
  width: 100%;
  height: 10vh;
  background: var(--layout-header-footer-background-color) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px 0px 12px !important;
}

.header .anticon-menu-unfold,
.anticon-menu-fold {
  font-size: 24px;
  cursor: pointer;
}

.content {
  overflow: hidden;
  position: relative !important;
  width: 100%;
  height: 100%;
  /* background: var(--layout-content-background-color); */
  background: transparent !important;
}

.controll .ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.upload-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--default-grey) !important;
  border-color: var(--default-grey) !important;
}

.upload-checkbox .ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  border: 0 !important;
}

.upload-checkbox .ant-checkbox .ant-checkbox-inner {
  background-color: var(--sidebar-background-color);
  border-color: var(--default-grey) !important;
}

.ant-checkbox-wrapper::after {
  display: none !important;
}

.react-pdf__Page__canvas {
  margin-top: -80px;
}

.synopsis_empty .ant-empty-image {
  height: 50px !important;
}

.synopsis_empty .ant-empty-image .ant-empty-img-default {
  height: 50px !important;
  width: 50px !important;
}

.scenario-usetool-container {
  width: 56%;
}

.scenario-usetool-container-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10;
  flex-direction: column;
  flex-wrap: wrap;
  width: 160px;
}

.scenario-usetool-sub-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  max-width: 156px;
  margin: 0px 10px 10px 0 !important;
}

.footer {
  width: 100%;
  height: var(--layout-footer-height);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--layout-header-footer-background-color) !important;
}

.footer .ant-menu-item-selected {
  background: var(--layout-menu-selected-background-color) !important;
  border-bottom: 5px solid var(--sider-bottom-menu-color) !important;
}

.logo {
  /* height: 32px; */
  margin: 0 20px;
  animation: logo-fadeIn 0.5s linear;
}

.icon {
  /* height: 32px; */
  /* height: 100px; */
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: icon-fadeIn 0.5s linear;
}

.logo-icon {
  display: none;
}

.ant-layout-sider-trigger {
  /* background: var(--layout-trigger-background-color) !important; */
  height: 8vh !important;
}

.ant-typography-edit,
.ant-typography-edit-content-confirm {
  display: none !important;
}

.ant-descriptions-item-content textarea {
  margin-left: 12px !important;
}

.reports {
  height: 100vh;
}

.auth-section-left {
  height: 100%;
  padding: 24px;
  justify-content: center;
}

.auth-section-right {
  padding: 24px;
  height: 100vh;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.auth-container {
  box-shadow: var(--secondary-box-shadow-color) 0px 48px 100px 0px;
  /* width: 100%; */
  border-radius: 12px !important;
  margin-bottom: 20px;
}

.auth-container .ant-card-head-title {
  padding-bottom: 0px !important;
}

.auth-container .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon,
.auth-container .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: var(--default-cta);
}

.auth-container .ant-steps-navigation .ant-steps-item::before {
  background-color: var(--default-cta);
}

#phone-input::-webkit-outer-spin-button,
#phone-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#phone-input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.ant-space.full-width {
  width: 100%;
}

.ant-space.full-width>* {
  flex: 1 !important;
}

@keyframes logo-fadeIn {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

@keyframes icon-fadeIn {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

/* access page styles */
.access-container {
  height: 100vh;
  overflow-y: scroll;
}

.access-container>.ant-space-item:last-child {
  flex: 1;
}

.guidance-tabs .ant-tabs-nav-list {
  color: var(--default-white);
  width: 100% !important;
  border: 1px solid var(--light-grey);
  border-radius: 25px;
  padding: 1px;
}

.guidance-tabs .ant-tabs-tab {
  padding: 10px 30px;
  margin: 2px !important;
  border-radius: 20px;
}

.scenario-tools-tab .ant-tabs-tab {
  padding: 8px 30px;
  margin: 2px !important;
  border-radius: 20px;
}

.guidance-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.guidance-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f1b24 !important;
}

.guidance-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--default-white) !important;
  border: none !important;
}

.guidance-tabs .ant-tabs-tab:hover {
  color: unset;
}

.guidance-tabs .ant-tabs-ink-bar {
  background: none !important;
}

.nested-guidance-tab .ant-tabs-nav-list {
  color: var(--default-black);
  border: none;
  display: flex !important;
  justify-content: center !important;
}

.nested-guidance-tab .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--primary-background) !important;
  border-radius: 20px !important;
}

.nested-guidance-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--default-white) !important;
}

/* Guidance CSS Style */

.guidance-select .ant-select-selector {
  background-color: var(--default-white) !important;
  border-color: var(--default-grey) !important;
  border-radius: 4px !important;
  height: 35px !important;
  align-items: center !important;
}

.carbonpanel-tabs .ant-tabs-tab {
  border-right: 1px solid var(--default-white);
  padding: 10px 23.3px;
  margin: 0 !important;
  background-color: var(--default-black);
  color: var(--default-white);
}

.carbon-drawer .ant-drawer-body {
  padding: 0 !important;
}

/* .carbon-drawer .ant-drawer .ant-drawer-content {
  width: 200% !important;
} */

.carbonpanel-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--default-white) !important;
}

.carbonpanel-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: var(--default-black) !important;
}

.carbonpanel-tabs .ant-tabs-ink-bar {
  background: none !important;
}

.login-authetication-card {
  width: 80%;
}

/* .guidance-tabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 1px solid var(--primary-background);
} */

.share-commentbox-btn-style {
  min-width: 100px;
  height: fit-content !important;
  border-radius: 8px !important;
  font-weight: bolder;
}

.view-dso-comment-button {
  color: red;
}

.dso-comment-popup-confirm .ant-popover-inner-content {
  height: 230px !important;
  width: 300px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.upload-failed-error-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upload-failed-error-box>.ant-space-item {
  width: 100%;
}

.autocompleteInput>div:first-of-type {
  width: 100%;
}

@media (max-width: 1240px) {
  .data-stories .ant-carousel .slick-slide {
    float: none;
    margin: 15px 0;
  }

  .data-stories .ant-carousel .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
    width: fit-content !important;
  }

  .scenario-usetool-container {
    width: 100%;
  }

  .scenario-usetool-container-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  .scenario-usetool-sub-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin: 0px 10px 10px 0 !important;
  }
}

@media (min-width: 1536px) {
  .login-authetication-card {
    width: 600px;
  }
}