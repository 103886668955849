/* ---------- Query for 100% zoom level----------*/
@media (min-width: 720px) and (max-height: 720px) {
  span:not(.ant-spin span),
  div.ant-typography {
    font-size: 0.75rem !important;
  }
}
@media (max-height: 720px) {
  /* ------ auth section styles------ */
  .auth-section-right h2 {
    font-size: 24px !important;
    margin-bottom: 24px !important;
  }
  .auth-container {
    max-width: 500px !important;
  }

  .auth-container .ant-form-item {
    margin-bottom: 12px;
  }

  .auth-container .ant-steps-navigation {
    padding: 0px;
  }

  /* --------- main menu style---------- */
  /* .sider:not(.ant-layout-sider-collapsed) {
    max-width: 180px !important;
    min-width: 180px !important;
    width: 180px !important;
  } */

  .sider .top-menu {
    height: 40vh !important;
    overflow-y: scroll;
  }

  .sider .ant-menu-item,
  .sider .ant-menu-submenu-title {
    display: flex !important;
    align-items: center !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  .sider .ant-menu-title-content {
    font-size: 0.7rem !important;
  }

  .sider .bottom-menu button {
    width: 100% !important;
  }

  /* .sider:not(.ant-layout-sider-collapsed) .ant-layout-sider-trigger {
    width: 180px !important;
  } */

  /* Controller styles */
  .controll .ant-drawer-content-wrapper {
    width: 300px !important;
  }

  .controlls-drawer-width {
    width: 300px !important;
  }

  .controll .ant-tree-title {
    width: 145px !important;
  }

  .react-resizable-handle,
  .react-resize-icon {
    left: 290px !important;
  }

  .expand-controls {
    left: 300px !important;
  }

  span:not(.ant-spin span),
  div.ant-typography {
    font-size: 0.75rem !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 0.75rem !important;
  }
  p {
    font-size: 0.8rem !important;
  }

  /* homepage - story - cards */

  .story-card,
  .story-card img {
    width: 300px !important;
    height: 300px !important;
  }

  .story-card-meta {
    width: 260px !important;
    max-height: 180px !important;
    min-height: 45px !important;
    height: auto;
  }

  .scenario-charts-open {
    margin-bottom: 33% !important;
  }
}

@media (max-height: 550px) {
  .sider .top-menu {
    height: 30vh !important;
    overflow-y: scroll;
  }
}
